import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Magestic = Loadable(lazy(() => import("./Magestic")));

const magesticRoutes = [
    {
        path: '/majestic',
        element: <Magestic />,
    },
]

export default magesticRoutes
