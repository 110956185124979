import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Alluserlist = Loadable(lazy(() => import("./alluserlist")));
const User2xlist = Loadable(lazy(() => import("./user2xlist")));


const alluserlistRoutes = [
    {
        path: '/alluserlist',
        element: <Alluserlist />,
    },
    {
        path: '/user2xlist',
        element: <User2xlist />,
    },
]

export default alluserlistRoutes
