import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import chartsRoute from 'app/views/charts/ChartsRoute'
import materialRoutes from 'app/views/material-kit/MaterialRoutes'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import primaryRoutes from 'app/views/primary/primaryRoutes'
import grandRoutes from 'app/views/grandRoutes/grandRoutes'
import royalRoutes from 'app/views/royalRoutes/royalRoutes'
import magesticRoutes from 'app/views/magesticRoutes/magesticRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import LoginRoutes from 'app/views/loginAuth/LoginRoutes'
import notificationRoutes from 'app/views/notification/notificationRoutes'
import treeRoutes from 'app/views/treeview/treeRoutes'
import alluserlistRoutes from "app/views/Alluserlist/alluserlistRoutes"
import settingRoutes from "app/views/setting/settingRoutes"

import { Navigate } from 'react-router-dom'

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [
                ...dashboardRoutes,
                ...chartsRoute,
                ...materialRoutes,
                ...primaryRoutes,
                ...grandRoutes,
                ...royalRoutes,
                ...magesticRoutes,
                ...notificationRoutes,
                ...treeRoutes,
                ...alluserlistRoutes,
                ...settingRoutes,

            ],
        },
        ...LoginRoutes,
        {
            path: '/',
            element: <Navigate to="dashboard" />,
        },
        {
            path: '/primary',
            element: <Navigate to="primary" />,
        },
        {
            path: '/grand',
            element: <Navigate to="grand" />,
        },
        {
            path: '/royal',
            element: <Navigate to="royal" />,
        },
        {
            path: '/majestic',
            element: <Navigate to="majestic" />,
        },
        {
            path: '/notification',
            element: <Navigate to="notification" />,
        },
        {
            path: '/search',
            element: <Navigate to="treeview" />,
        },
        {
            path: '/alluserlist',
            element: <Navigate to="alluserlist" />,
        },
        {
            path: '/user2xlist',
            element: <Navigate to="user2xlist" />,
        },
        {
            path: '/setting',
            element: <Navigate to="setting" />,
        },
        {
            path: '/changepassword',
            element: <Navigate to="changepassword" />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
