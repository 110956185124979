import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Search = Loadable(lazy(() => import("./treeview")));

const treeRoutes = [
    {
        path: '/search',
        element: <Search />,
    },
]

export default treeRoutes
