import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Setting = Loadable(lazy(() => import("./setting")));
const Changepassword = Loadable(lazy(() => import("./changepassword")));

const settingRoutes = [
	{
		path: '/changepassword',
        element: <Changepassword />,
	},
    {
        path: '/setting',
        element: <Setting />,
    },
]

export default settingRoutes
