import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Primary = Loadable(lazy(() => import("./Primary")));

const primaryRoutes = [
    {
        path: '/primary',
        element: <Primary />,
    },
]

export default primaryRoutes
