export const navigations = [
    {
        name: 'Dashboard',
        path: '/',
        // icon: 'dashboard',
    },
     {
        name: 'Primary / Autopool 1',
        path: '/primary',
        // icon: 'brightness_low',
    },
     {
        name: 'Grand / Autopool 2',
        path: '/grand',
        // icon: 'brightness_medium',
    },
     {
        name: 'Royal / Autopool 3',
        path: '/royal',
        // icon: 'brightness_auto',
    },
     {
        name: 'Majestic / Autopool 4',
        path: '/majestic',
        // icon: 'brightness_high',
    },
     {
        name: 'All User List',
        path: '/alluserlist',
        // icon: 'brightness_high',
    },
     {
        name: '2x User List',
        path: '/user2xlist',
        // icon: 'brightness_high',
    },
    {
        name: 'Tree Prediction',
        path: '/search',
        // icon: 'brightness_high',
    },
    {
        name: 'Notification',
        path: '/notification',
        // icon: 'brightness_high',
    },
    {
        name: 'Settings',
        path: '/setting',
        //icon: 'dashboard',
    }
]
