let key = {};
if (process.env.NODE_ENV == "production") {
  key = {
     baseUrl: "https://controlsapi.bsmartcash.io/adminapi",
    imageUrl: "https://controls.bsmartcash.io/",
    imgURL: "https://controlsapi.bsmartcash.io/",
    loaderColor: "#0a0a3a",
    txUrl: "https://bscscan.com/tx/",
    netWorkUrl: "https://bsc-dataseed.binance.org/",
    contractaddress : "0x4b88a7408274BA32ADda4D3E0F24231b5b82bBD4",
    busdcontract : "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    projectId : "cb89ebb21cdccb2e1b591e189e27706a",
    netWorkversion: 56,
    secretkey: "QUANTUM*26@7#$%@@#$@!&^%*#",
  };
} else {
  key = {
    baseUrl: "http://localhost:3098/adminapi",
    imageUrl: "http://localhost:3550/",
    imgURL: "https://productionapi.soldaitswap.finance/",
    loaderColor: "#0a0a3a",
    txUrl: "https://testnet.bscscan.com/tx/",
    netWorkUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    contractaddress : "0xe029CFCa0de7bcD880F9A0905eB1e81FBA7A0E60",
    busdcontract : "0x8ce7720fd183aec96b676fd8250724b05b0d7a6f",
    projectId : "cb89ebb21cdccb2e1b591e189e27706a",
    netWorkversion: 97,
    secretkey: "QUANTUM*26@7#$%@@#$@!&^%*#",
  };
}

export default key;
