import '../fake-db'
import React from 'react'
import { Store } from './redux/Store'
import { Provider } from 'react-redux'
import { AllPages } from './routes/routes'
import { MatxTheme } from 'app/components'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { ToastContainer } from 'react-toastify'
import config from "./config/config";
import 'react-toastify/dist/ReactToastify.css'
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { bsc,bscTestnet} from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc,bscTestnet],
  [infuraProvider({
    apiKey: config.projectId,
  }), publicProvider()],
)

const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: config.projectId,
        version:2
      },
    })
  ],
  publicClient,
  webSocketPublicClient,
});

const App = () => {
    const all_pages = useRoutes(AllPages())

    return (
        <Provider store={Store}>
            <SettingsProvider>
                <ToastContainer />
                <MatxTheme>
                <WagmiConfig config={wconfigInitial}>
                    <AuthProvider>{all_pages}</AuthProvider>
                </WagmiConfig>
                </MatxTheme>
            </SettingsProvider>
        </Provider>
    )
}

export default App
