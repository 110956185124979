import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Grand = Loadable(lazy(() => import("./Grand")));

const grandRoutes = [
    {
        path: '/grand',
        element: <Grand />,
    },
]

export default grandRoutes
