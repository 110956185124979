import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Notification = Loadable(lazy(() => import("./Notification")));

const NotificationRoutes = [
    {
        path: '/notification',
        element: <Notification />,
    },
]

export default NotificationRoutes
