import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Royal = Loadable(lazy(() => import("./Royal")));

const royalRoutes = [
    {
        path: '/royal',
        element: <Royal />,
    },
]

export default royalRoutes
